.endNoteLine {
  font-size: 0.7rem;
  vertical-align: top;
}

.ql-font-serif {
  font-family: "Bitter", serif !important;
}

.ql-font-sans {
  font-family: "Work Sans", sans-serif !important;
}

.ql-editor .ql-font-monospace {
  font-family: "Dancing Script", cursive !important;
}

.ql-picker-item[data-value="sans"]::before {
  content: "Work Sans" !important;
  font-family: "Work Sans", sans-serif !important;
}
.ql-picker-item[data-value="serif"]::before {
  content: "Bitter" !important;
  font-family: "Bitter", serif !important;
}

.ql-picker-item[data-value="monospace"]::before {
  content: "Dancing Script" !important;
  font-family: "Dancing Script", cursive !important;
}

.ql-picker-label[data-value="sans"]::before {
  content: "Work Sans" !important;
  font-family: "Work Sans", sans-serif !important;
}
.ql-picker-label[data-value="serif"]::before {
  content: "Bitter" !important;
  font-family: "Bitter", serif !important;
}
.ql-picker-label[data-value="monospace"]::before {
  content: "Dancing Script" !important;
  font-family: "Dancing Script", cursive !important;
}

.ql-picker-item[data-value="1rem"]::before {
  content: "Normal" !important;
  font-size: 1rem !important;
}
.ql-picker-item[data-value="1.5rem"]::before {
  content: "Médio" !important;
  font-size: 1.5rem !important;
}

.ql-picker-item[data-value="2rem"]::before {
  content: "Grande" !important;
  font-size: 2rem !important;
}

.ql-picker-label[data-value="1rem"]::before {
  content: "Normal" !important;
}
.ql-picker-label[data-value="1.5rem"]::before {
  content: "Médio" !important;
}

.ql-picker-label[data-value="2rem"]::before {
  content: "Grande" !important;
}

.ql-picker-item[data-value="1"]::before {
  content: "Título" !important;
}
.ql-picker-item[data-value="2"]::before {
  content: "Sub-Título" !important;
}

.ql-picker-label[data-value="1"]::before {
  content: "Título" !important;
}
.ql-picker-label[data-value="2"]::before {
  content: "Sub-Título" !important;
}
